import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container } from 'semantic-ui-react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { isEmpty, get } from 'lodash';
import client from './apollo';

import APP_PATH from './constants/paths';
import ACCESS from './constants/access';
import { UNAUTHORIZED } from './helpers/customEvents';

import Header from './views/Shared/Header';
import NotFound from './views/NotFound';
import ErrorPage from './views/ErrorPage';
import Home from './containers/Home';
import Login from './containers/Login';
import Logout from './containers/Logout';
import requireAuth from './containers/RequireAuth';
import authorize from './containers/Authorize';
import GuaranteePurchasesContainer from './containers/GuaranteePurchasesTabs';
import ReturnInvoicesContainer from './containers/ReturnInvoicesTabs';

import FlatRateOffersContainer from './containers/FlatRateOffers';
import ExternalTransactionsContainer from './containers/ExternalTransactions';
import ExternalAuctionTransactionsContainer from './containers/ExternalAuctionTransactions';
import VehicleStatusTracker from './containers/VehicleStatusTracker';
import NewNetworkPlusVehicle from './containers/NewNetworkPlusVehicle';
import NetworkPlusOfferApproval from './containers/NetworkPlusOfferApproval';
import MarketIndex from './containers/MarketIndex';
import UserDigestSettings from './containers/UserDigestSettings';
import PendingGuarantees from './containers/PendingGuarantees';
import VinBlacklists from './containers/VinBlacklists';
import DealershipBlacklists from './containers/DealershipBlacklists';
import OfferUpgrades from './containers/OfferUpgrades';
import DealerDashboard from './containers/DealerDashboard';
import SurchargePriceUploader from './containers/SurchargePriceUploader';
import fileUploader from './containers/fileUploader';
import ProductSelection from './containers/ProductSelection';
import OfferAcknowledgement from './containers/OfferAcknowledgement';
import ExternalVehicleBatches from './containers/ExternalVehicleBatches';
import ExternalVehicles from './containers/ExternalVehicles';
import AuctionAccessBlacklists from './containers/AuctionAccessBlacklists';
import BulkDealerActions from './containers/BulkDealerActions';
import MarketForecastUploader from './containers/MarketForecastUploader';
import ReturnCertificate from './containers/ReturnCertificate';
import FeatureFlag from './containers/FeatureFlag';
import GuaranteeHistoryContainer from './containers/GuaranteeHistoryTabs';
import getpingStatusQuery from './graphql/getPingStatusQuery';
import LoginCallback from './containers/LoginCallback';
import LogoutCallback from './containers/LogoutCallback';
import LogoutError from './containers/LogoutError';
import Users from './containers/Users';
import GuaranteeCharge from './containers/GuaranteeCharge';
import getTappyMaintenanceStatusQuery from './graphql/getTappyMaintenanceStatusQuery';
import MaintenancePage from './containers/MaintenancePage';
import Subscriptions from './containers/Subscriptions';
import SupportRequest from './containers/SupportRequest';
import ExpiredVraSettingsContainer from './containers/ExpiredVraSettingsTabs';
import DealerManager from './containers/DealerManager';

class App extends Component {
  state = { pingEnabled: '', maintenanceStatusEnabled: false };

  componentWillMount() {
    const { history } = this.props;
    window.addEventListener(
      UNAUTHORIZED,
      () => {
        history.push(APP_PATH.LOGOUT);
      },
      false
    );

    client
      .query({
        query: getTappyMaintenanceStatusQuery
      })
      .then(({ data }) => {
        if (get(data, 'tappyMaintenanceFlagStatus.active')) {
          this.setState({
            maintenanceStatusEnabled: get(
              data,
              'tappyMaintenanceFlagStatus.active'
            )
          });
        }
      });

    client
      .query({
        query: getpingStatusQuery
      })
      .then(({ data }) => {
        this.setState({
          pingEnabled: get(data, 'pingFeatureFlagStatus.active')
        });
      })
      .catch(error => {
        this.setState({
          pingEnabled: false
        });
      });
  }

  render() {
    const {
      user,
      location: { pathname }
    } = this.props;

    const loc = window.location.pathname;

    let istokenPresent = false;
    const { pingEnabled, maintenanceStatusEnabled } = this.state;

    if (maintenanceStatusEnabled) {
      return <MaintenancePage />;
    }

    if (pathname && pathname.includes('vst')) {
      const token = pathname.split('/')[3];
      istokenPresent = !isEmpty(token) && token !== ':token';
    }

    if (
      loc.includes('loginCallback') ||
      loc.includes('logoutCallback') ||
      loc.includes('logoutError')
    ) {
      window.location = `/#${window.location.pathname}${window.location.search}`;
    }

    return (
      <div>
        {!istokenPresent && <Header user={user} pingEnabled={pingEnabled} />}
        <Container
          style={{ width: '95%', marginTop: '4em', marginBottom: '7em' }}
        >
          <Switch>
            <Route
              path={APP_PATH.LOGIN}
              component={() => (
                <Login
                  pingEnabled={pingEnabled}
                  maintenanceStatusEnabled={maintenanceStatusEnabled}
                />
              )}
            />
            <Route
              path={APP_PATH.LOGOUT}
              component={() => (
                <Logout
                  pingEnabled={pingEnabled}
                  maintenanceStatusEnabled={maintenanceStatusEnabled}
                />
              )}
            />
            <Route path={APP_PATH.REDIRECT_URL} component={LoginCallback} />
            <Route path={APP_PATH.LOGOUT_CALLBACK} component={LogoutCallback} />
            <Route path={APP_PATH.LOGOUT_ERROR} component={LogoutError} />
            <Route path={APP_PATH.ERROR_PAGE} component={ErrorPage} />
            <Route path={APP_PATH.BASE} component={requireAuth(Home)} exact />
            <Route
              path={APP_PATH.GUARANTEED_PURCHASES}
              component={authorize(ACCESS.GUARANTEED_PURCHASES, {
                refreshSearch: true
              })(GuaranteePurchasesContainer)}
              exact
            />
            <Route
              path={APP_PATH.RETURN_INVOICES}
              component={authorize(ACCESS.RETURN_INVOICES, {
                refreshSearch: true
              })(ReturnInvoicesContainer)}
              exact
            />

            <Route
              path={APP_PATH.DEALSHIELD360_OFFERS}
              component={authorize(ACCESS.DEALSHIELD360_OFFERS)(
                FlatRateOffersContainer
              )}
              exact
            />

            <Route
              path={APP_PATH.DEALSHIELD360_OFFERS_WITH_ID}
              component={authorize(ACCESS.DEALSHIELD360_OFFERS)(
                FlatRateOffersContainer
              )}
              exact
            />

            <Route
              path={APP_PATH.EXTERNAL_TRANSACTIONS}
              component={authorize(ACCESS.EXTERNAL_TRANSACTIONS, {
                refreshSearch: true
              })(ExternalTransactionsContainer)}
              exact
            />

            <Route
              path={APP_PATH.EXTERNAL_AUCTION_TRANSACTIONS}
              component={authorize(ACCESS.EXTERNAL_TRANSACTIONS, {
                refreshSearch: true
              })(ExternalAuctionTransactionsContainer)}
              exact
            />

            <Route
              path={APP_PATH.VEHICLE_STATUS_TRACKER}
              component={authorize(ACCESS.VEHICLE_STATUS_TRACKER)(
                VehicleStatusTracker
              )}
              exact
            />

            <Route
              path={APP_PATH.VEHICLE_STATUS_TRACKER_WITH_TOKEN}
              component={authorize(ACCESS.VEHICLE_STATUS_TRACKER)(
                VehicleStatusTracker
              )}
              exact
            />

            <Route
              path={APP_PATH.NETWORK_PLUS_VEHICLE_ENTRY}
              component={authorize(ACCESS.NETWORK_PLUS_VEHICLE_ENTRY)(
                NewNetworkPlusVehicle
              )}
              exact
            />

            <Route
              path={APP_PATH.NETWORK_PLUS_OFFER_APPROVAL}
              component={authorize(ACCESS.NETWORK_PLUS_OFFER_APPROVAL)(
                NetworkPlusOfferApproval
              )}
              exact
            />
            <Route
              path={APP_PATH.MARKET_INDEX}
              component={authorize(ACCESS.MARKET_INDEX)(MarketIndex)}
              exact
            />
            <Route
              path={APP_PATH.USER_DIGEST_SETTINGS}
              component={authorize(ACCESS.USER_DIGEST_SETTINGS)(
                UserDigestSettings
              )}
              exact
            />
            <Route
              path={APP_PATH.PENDING_GUARANTEES}
              component={authorize(ACCESS.LOSS_PREVENTION_SECTION, {
                refreshSearch: true
              })(PendingGuarantees)}
              exact
            />
            <Route
              path={APP_PATH.EXTERNAL_VEHICLE_BATCHES}
              component={authorize(ACCESS.EXTERNAL_VEHICLE_BATCHES, {
                refreshSearch: true
              })(ExternalVehicleBatches)}
              exact
            />
            <Route
              path={APP_PATH.EXTERNAL_VEHICLE_BATCH}
              component={authorize(ACCESS.EXTERNAL_VEHICLE_BATCHES)(
                ExternalVehicles
              )}
              exact
            />
            <Route
              path={APP_PATH.VIN_BLACKLISTS}
              component={authorize(ACCESS.VIN_BLACKLISTS)(VinBlacklists)}
              exact
            />
            <Route
              path={APP_PATH.AUCTIONACCESS_BLACKLISTS}
              component={authorize(ACCESS.AUCTIONACCESS_BLACKLISTS)(
                AuctionAccessBlacklists
              )}
              exact
            />
            <Route
              path={APP_PATH.DEALERSHIP_BLACKLISTS}
              component={authorize(ACCESS.DEALERSHIP_BLACKLISTS)(
                DealershipBlacklists
              )}
              exact
            />
            <Route
              path={APP_PATH.EXPIRED_VRA_SETTINGS}
              component={authorize(ACCESS.EXPIRED_VRA_SETTINGS)(
                ExpiredVraSettingsContainer
              )}
              exact
            />
            <Route
              path={APP_PATH.OFFER_UPGRADES}
              component={authorize(ACCESS.UPGRADE_OFFERS)(OfferUpgrades)}
              exact
            />
            <Route
              path={APP_PATH.BULK_DEALER_ACTIONS}
              component={authorize(ACCESS.UPGRADE_OFFERS)(BulkDealerActions)}
              exact
            />
            <Route
              path={APP_PATH.MARKET_FORECAST_UPLOAD}
              component={authorize(ACCESS.MARKET_INDEX)(MarketForecastUploader)}
              exact
            />
            <Route
              path={APP_PATH.DEALER_DASHBOARD}
              component={authorize(ACCESS.DEALER_DASHBOARD)(DealerDashboard)}
              exact
            />
            <Route
              path={APP_PATH.SURCHARGE_PRICE_UPLOAD}
              component={authorize(ACCESS.SURCHARGE_PRICE)(
                SurchargePriceUploader
              )}
              exact
            />
            <Route
              path={APP_PATH.PRODUCT_SELECTION}
              component={authorize(ACCESS.DEALER_DASHBOARD)(ProductSelection)}
              exact
            />
            <Route
              path={APP_PATH.ACKNOWLEDGEMENT_PAGE}
              component={authorize(ACCESS.DEALER_DASHBOARD)(
                OfferAcknowledgement
              )}
              exact
            />
            <Route
              path={APP_PATH.RETURN_CERTIFICATE}
              component={authorize(ACCESS.RETURN_INVOICES)(ReturnCertificate)}
              exact
            />
            <Route
              path={APP_PATH.FEATURE_FLAG}
              component={authorize(ACCESS.FEATURE_FLAG)(FeatureFlag)}
              exact
            />
            <Route
              path={APP_PATH.GUARANTEE_HISTORY}
              component={authorize(ACCESS.GUARANTEE_HISTORY, {
                refreshSearch: true
              })(GuaranteeHistoryContainer)}
              exact
            />
            <Route
              path={APP_PATH.USERS}
              component={authorize(ACCESS.USERS_SECTION)(Users)}
              exact
            />
            <Route
              path={APP_PATH.SUBSCRIPTION}
              component={authorize(ACCESS.SUBSCRIPTION, {
                refreshSearch: true
              })(Subscriptions)}
              exact
            />
            <Route
              path={APP_PATH.GUARANTEE_CHARGE}
              component={authorize(ACCESS.GUARANTEE_CHARGE_SECTION)(
                GuaranteeCharge
              )}
              exact
            />
            <Route
              path={APP_PATH.SUPPORT_REQUEST}
              component={authorize(ACCESS.ADMIN_SECTION)(SupportRequest)}
              exact
            />
            <Route
              path={APP_PATH.FILE_UPLOADER}
              component={authorize(ACCESS.ADMIN_SECTION)(fileUploader)}
              exact
            />
            <Route
              path={APP_PATH.DEALER_MANAGER}
              component={authorize(ACCESS.ADMIN_SECTION)(DealerManager)}
              exact
            />
            <Route component={NotFound} />
          </Switch>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    data: { user }
  } = state;
  return {
    user
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps)
)(App);
