const base = '/';

export default {
  BASE: base,
  DEALER_INFORMATION: `${base}dealer_information/`,
  GUARANTEED_PURCHASES: `${base}guarantee_purchases/`,
  GUARANTEED_PURCHASE_EDIT: `${base}guarantee_purchases/:id/edit`,
  RETURN_INVOICES: `${base}return_invoices/`,
  VIN_BLACKLISTS: `${base}vin_blacklists/`,
  AUCTIONACCESS_BLACKLISTS: `${base}auctionaccess_blacklists/`,
  DEALERSHIP_BLACKLISTS: `${base}dealership_blacklists/`,
  DEALSHIELD360_OFFERS: `${base}flat_rate_offers/`,
  DEALSHIELD360_OFFERS_WITH_ID: `${base}flat_rate_offers/:id/edit`,
  DASHBOARD_SCORES: `${base}dashboard_scores/`,
  EXTERNAL_TRANSACTIONS: `${base}external_transactions/`,
  EXTERNAL_AUCTION_TRANSACTIONS: `${base}external_auction_transactions/`,
  LOGIN: `${base}login`,
  LOGOUT: `${base}logout`,
  VEHICLE_STATUS_TRACKER_WITH_TOKEN: `${base}vst/:purchaseId/:token`,
  VEHICLE_STATUS_TRACKER: `${base}vst/:purchaseId`,
  NETWORK_PLUS_VEHICLE_ENTRY: `${base}network_plus_vehicles/new`,
  NETWORK_PLUS_OFFER_APPROVAL: `${base}network_plus_offer/:id/approval`,
  MARKET_INDEX: `${base}market_index`,
  USER_DIGEST_SETTINGS: `${base}user_digest_settings`,
  PENDING_GUARANTEES: `${base}pending_guarantees`,
  EXTERNAL_VEHICLE_BATCHES: `${base}external_vehicles_batches`,
  EXTERNAL_VEHICLE_BATCH: `${base}external_vehicles_batch/:id`,
  OFFER_UPGRADES: `${base}offer_upgrades`,
  DEALER_DASHBOARD: `${base}dealer_dashboard`,
  SURCHARGE_PRICE_UPLOAD: `${base}surcharge_price_upload`,
  FILE_UPLOADER: `${base}file_uploader`,
  PRODUCT_SELECTION: `${base}product_selection`,
  ACKNOWLEDGEMENT_PAGE: `${base}offer_acknowledgement`,
  BULK_DEALER_ACTIONS: `${base}bulk_dealer_actions`,
  MARKET_FORECAST_UPLOAD: `${base}market_forecast_upload`,
  RETURN_CERTIFICATE: `${base}return_certificate/:returnInvoiceId`,
  FEATURE_FLAG: `${base}feature_flags`,
  GUARANTEE_HISTORY: `${base}guarantee_history`,
  REDIRECT_URL: `${base}loginCallback`,
  LOGOUT_CALLBACK: `${base}logoutCallback`,
  LOGOUT_ERROR: `${base}logoutError`,
  USERS: `${base}users`,
  SUBSCRIPTION: `${base}subscription`,
  ERROR_PAGE: `${base}errorpage`,
  GUARANTEE_CHARGE: `${base}guaranteeCharge`,
  SUPPORT_REQUEST: `${base}supportRequest`,
  EXPIRED_VRA_SETTINGS: `${base}expired_vra_settings`,
  DEALER_MANAGER: `${base}dealer_manager`
};
