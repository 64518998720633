import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Grid, Header } from 'semantic-ui-react';
import { isEmpty } from 'lodash';

import APP_PATH from '../constants/paths';
import ACCESS from '../constants/access';
import { allowAccess } from '../utilities/user';
import { clearNextPath } from '../data/nextPath/actions';

class Home extends Component {
  constructor(props) {
    super(props);
    this.gotoRedirect();

    if (!isEmpty(props.user) && !isEmpty(props.nextPath)) {
      props.clearNextPath({});
      props.history.push(props.nextPath);
    }
  }

  gotoRedirect = () => {
    const redirectPath = localStorage.getItem('external_path');
    if (!isEmpty(redirectPath)) {
      setTimeout(() => {
        localStorage.removeItem('external_path');
        window.location = window.location.origin + '#' + redirectPath;
      }, 1000);
    }
  };

  render() {
    const { user } = this.props;
    const showDashboard = process.env.REACT_APP_SHOW_ACO_DASHBOARD;

    return (
      <div>
        {allowAccess(user, ACCESS.GUARANTEE_SECTION) && (
          <Grid columns={3}>
            <Grid.Row>
              <Grid.Column>
                <Header size="large">Guarantee Administration</Header>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              {allowAccess(user, ACCESS.GUARANTEED_PURCHASES) && (
                <Grid.Column style={{ marginTop: '2em', marginBottom: '2em' }}>
                  <Header size="medium">
                    <Link to={APP_PATH.GUARANTEED_PURCHASES}>
                      Guarantee Purchases
                    </Link>
                  </Header>
                  <p>View and edit guarantee purchases.</p>
                </Grid.Column>
              )}

              {allowAccess(user, ACCESS.RETURN_INVOICES) && (
                <Grid.Column style={{ marginTop: '2em', marginBottom: '2em' }}>
                  <Header size="medium">
                    <Link to={APP_PATH.RETURN_INVOICES}>Return Invoices</Link>
                  </Header>
                  <p>View VRAs and edit them.</p>
                </Grid.Column>
              )}

              {allowAccess(user, ACCESS.EXTERNAL_TRANSACTIONS) && (
                <Grid.Column style={{ marginTop: '2em', marginBottom: '2em' }}>
                  <Header size="medium">
                    <Link to={APP_PATH.EXTERNAL_AUCTION_TRANSACTIONS}>
                      Partner Auction Transactions
                    </Link>
                  </Header>
                  <p>Details of transactions from Auction Edge</p>
                </Grid.Column>
              )}

              {allowAccess(user, ACCESS.NETWORK_PLUS_VEHICLE_ENTRY) && (
                <Grid.Column style={{ marginTop: '2em', marginBottom: '2em' }}>
                  <Header size="medium">
                    <Link to={APP_PATH.NETWORK_PLUS_VEHICLE_ENTRY}>
                      Enter Network+ Vehicle
                    </Link>
                  </Header>
                  <p>
                    Enter a Network+ vehicle and upload related documentation
                  </p>
                </Grid.Column>
              )}

              {allowAccess(user, ACCESS.EXTERNAL_VEHICLE_BATCHES) && (
                <Grid.Column style={{ marginTop: '2em', marginBottom: '2em' }}>
                  <Header size="medium">
                    <Link to={APP_PATH.EXTERNAL_VEHICLE_BATCHES}>
                      Network+ Batches
                    </Link>
                  </Header>
                  <p>Manage Network+ Batches</p>
                </Grid.Column>
              )}

              {allowAccess(user, ACCESS.EXTERNAL_TRANSACTIONS) && (
                <Grid.Column style={{ marginTop: '2em', marginBottom: '2em' }}>
                  <Header size="medium">
                    <Link to={APP_PATH.EXTERNAL_TRANSACTIONS}>
                      External Auction Transactions
                    </Link>
                  </Header>
                  <p>Details of transactions from Auction Edge</p>
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
        )}

        {allowAccess(user, ACCESS.LOSS_PREVENTION_SECTION) && (
          <Grid columns={3}>
            <Grid.Row>
              <Grid.Column>
                <Header size="large">Loss Prevention Administration</Header>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column style={{ marginTop: '2em', marginBottom: '2em' }}>
                <Header size="medium">
                  <Link to={APP_PATH.PENDING_GUARANTEES}>
                    Pending Guarantees Offers
                  </Link>
                </Header>
                <p>Approve and invalidate pending guarantee offers.</p>
              </Grid.Column>
              <Grid.Column style={{ marginTop: '2em', marginBottom: '2em' }}>
                <Header size="medium">
                  <Link to={APP_PATH.VIN_BLACKLISTS}>Vin Blacklists</Link>
                </Header>
                <p>Black list VIN.</p>
              </Grid.Column>
              <Grid.Column style={{ marginTop: '2em', marginBottom: '2em' }}>
                <Header size="medium">
                  <Link to={APP_PATH.AUCTIONACCESS_BLACKLISTS}>
                    Auction Access Blacklists
                  </Link>
                </Header>
                <p>Black list Auction Access.</p>
              </Grid.Column>
              <Grid.Column style={{ marginTop: '2em', marginBottom: '2em' }}>
                <Header size="medium">
                  <Link to={APP_PATH.DEALERSHIP_BLACKLISTS}>
                    Dealership Blacklists
                  </Link>
                </Header>
                <p>Black list Dealership.</p>
              </Grid.Column>
              <Grid.Column style={{ marginTop: '2em', marginBottom: '2em' }}>
                <Header size="medium">
                  <Link to={APP_PATH.EXPIRED_VRA_SETTINGS}>
                    Expired VRA Settings
                  </Link>
                </Header>
                <p>Expired VRA Settings.</p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}

        {allowAccess(user, ACCESS.DEALSHIELD360_SECTION) && (
          <Grid columns={3}>
            <Grid.Row>
              <Grid.Column>
                <Header size="large">Dealshield 360 Administration</Header>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              {allowAccess(user, ACCESS.DEALSHIELD360_OFFERS) && (
                <Grid.Column style={{ marginTop: '2em', marginBottom: '2em' }}>
                  <Header size="medium">
                    <Link to={APP_PATH.DEALSHIELD360_OFFERS}>
                      Dealshield 360 Offers
                    </Link>
                  </Header>
                  <p>Find flat rate offers accepted by dealers.</p>
                </Grid.Column>
              )}

              {allowAccess(user, ACCESS.UPGRADE_OFFERS) && (
                <Grid.Column style={{ marginTop: '2em', marginBottom: '2em' }}>
                  <Header size="medium">
                    <Link to={APP_PATH.OFFER_UPGRADES}>Offer Upgrades</Link>
                  </Header>
                  <p>Offer Upgrade links for DS customers </p>
                </Grid.Column>
              )}
              {allowAccess(user, ACCESS.UPGRADE_OFFERS) && (
                <Grid.Column style={{ marginTop: '2em', marginBottom: '2em' }}>
                  <Header size="medium">
                    <Link to={APP_PATH.BULK_DEALER_ACTIONS}>
                      Bulk Dealer Actions
                    </Link>
                  </Header>
                  <p />
                </Grid.Column>
              )}

              {allowAccess(user, ACCESS.DEALER_DASHBOARD) &&
                showDashboard === 'true' && (
                  <Grid.Column
                    style={{ marginTop: '2em', marginBottom: '2em' }}
                  >
                    <Header size="medium">
                      <Link to={APP_PATH.DEALER_DASHBOARD}>
                        Dealer Dashboard
                      </Link>
                    </Header>
                    <p>Dealer Dashbord</p>
                  </Grid.Column>
                )}
              {allowAccess(user, ACCESS.SURCHARGE_PRICE) &&
                showDashboard === 'true' && (
                  <Grid.Column
                    style={{ marginTop: '2em', marginBottom: '2em' }}
                  >
                    <Header size="medium">
                      <Link to={APP_PATH.SURCHARGE_PRICE_UPLOAD}>
                        Surcharge Price Uploader
                      </Link>
                    </Header>
                    <p>UI to upload Surcharge Price using csv file</p>
                  </Grid.Column>
                )}
            </Grid.Row>
          </Grid>
        )}

        {allowAccess(user, ACCESS.FINANCE_SECTION) && (
          <Grid columns={3}>
            <Grid.Row>
              <Grid.Column>
                <Header size="large">Finance Administration</Header>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              {allowAccess(user, ACCESS.GUARANTEE_HISTORY) && (
                <Grid.Column style={{ marginTop: '2em', marginBottom: '2em' }}>
                  <Header size="medium">
                    <Link to={APP_PATH.GUARANTEE_HISTORY}>
                      Guarantee History Report
                    </Link>
                  </Header>
                  <p>View guarantee lifecycle</p>
                </Grid.Column>
              )}
              {allowAccess(user, ACCESS.GUARANTEE_CHARGE_SECTION) && (
                <Grid.Column style={{ marginTop: '2em', marginBottom: '2em' }}>
                  <Header size="medium">
                    <Link to={APP_PATH.GUARANTEE_CHARGE}>
                      Guarantee Charge / Credit
                    </Link>
                  </Header>
                  <p>Push Guarantee Charge / Credit</p>
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
        )}

        {allowAccess(user, ACCESS.ADMIN_SECTION) && (
          <Grid columns={3}>
            <Grid.Row>
              <Grid.Column>
                <Header size="large">Tappy Administration</Header>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              {allowAccess(user, ACCESS.DEALER_INFORMATION) && (
                <Grid.Column style={{ marginTop: '2em', marginBottom: '2em' }}>
                  <Header size="medium">
                    <Link to={APP_PATH.DEALER_INFORMATION}>
                      Dealer Information
                    </Link>
                  </Header>
                  <p>View details of dealers</p>
                </Grid.Column>
              )}

              {allowAccess(user, ACCESS.MARKET_INDEX) && (
                <Grid.Column style={{ marginTop: '2em', marginBottom: '2em' }}>
                  <Header size="medium">
                    <Link to={APP_PATH.MARKET_INDEX}>Market Index</Link>
                  </Header>
                  <p>View details of market index and factors</p>
                </Grid.Column>
              )}

              {allowAccess(user, ACCESS.MARKET_INDEX) && (
                <Grid.Column style={{ marginTop: '2em', marginBottom: '2em' }}>
                  <Header size="medium">
                    <Link to={APP_PATH.MARKET_FORECAST_UPLOAD}>
                      Market Forecast Uploader
                    </Link>
                  </Header>
                  <p>UI to upload market forecast using csv file</p>
                </Grid.Column>
              )}
            </Grid.Row>

            <Grid.Row>
              {allowAccess(user, ACCESS.USER_DIGEST_SETTINGS) && (
                <Grid.Column style={{ marginTop: '2em', marginBottom: '2em' }}>
                  <Header size="medium">
                    <Link to={APP_PATH.USER_DIGEST_SETTINGS}>
                      User Digest Settings
                    </Link>
                  </Header>
                  <p>Manage User Digest Settings</p>
                </Grid.Column>
              )}

              {allowAccess(user, ACCESS.FEATURE_FLAG_SECTION) && (
                <Grid.Column style={{ marginTop: '2em', marginBottom: '2em' }}>
                  <Header size="medium">
                    <Link to={APP_PATH.FEATURE_FLAG}>Feature Flag</Link>
                  </Header>
                  <p>Toggle features on DS.com and Mission Control</p>
                </Grid.Column>
              )}
              {allowAccess(user, ACCESS.USERS_SECTION) && (
                <Grid.Column style={{ marginTop: '2em', marginBottom: '2em' }}>
                  <Header size="medium">
                    <Link to={APP_PATH.USERS}>Users</Link>
                  </Header>
                  <p>User administration</p>
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
        )}

        {allowAccess(user, ACCESS.IT_ADMINISTRATION_SECTION) && (
          <Grid columns={3}>
            <Grid.Row>
              <Grid.Column>
                <Header size="large">IT Support Administration</Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column style={{ marginTop: '2em', marginBottom: '2em' }}>
                <Header size="medium">
                  <Link to={APP_PATH.SUBSCRIPTION}>Subscription Manager</Link>
                </Header>
                <p>Create and update dealer subscription</p>
              </Grid.Column>
              <Grid.Column style={{ marginTop: '2em', marginBottom: '2em' }}>
                <Header size="medium">
                  <Link to={APP_PATH.FILE_UPLOADER}>File Uploader</Link>
                </Header>
                <p>Upload And Process CSV Files To APMS</p>
              </Grid.Column>
              <Grid.Column style={{ marginTop: '2em', marginBottom: '2em' }}>
                <Header size="medium">
                  <Link to={APP_PATH.SUPPORT_REQUEST}>Support Request</Link>
                </Header>
                <p>Post request to APMS</p>
              </Grid.Column>
              <Grid.Column style={{ marginTop: '2em', marginBottom: '2em' }}>
                <Header size="medium">
                  <Link to={APP_PATH.DEALER_MANAGER}>Dealer Manager</Link>
                </Header>
                <p>Manage Dealer Information</p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </div>
    );
  }
}

Home.propTypes = {
  history: PropTypes.object
};

const mapStateToProps = state => {
  const {
    data: { nextPath }
  } = state;
  return { nextPath };
};

const mapDispatchToProps = dispatch => ({
  clearNextPath: fields => {
    dispatch(clearNextPath());
  }
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Home);
