import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Menu, Dropdown, Container } from 'semantic-ui-react';
import APP_PATH from '../../constants/paths';
import { connect } from 'react-redux';
import ACCESS from '../../constants/access';
import { allowAccess } from '../../utilities/user';
const showDashboard = process.env.REACT_APP_SHOW_ACO_DASHBOARD;

const HeaderComponent = ({ user, label, pingEnabled }) => (
  <div>
    <Menu stackable>
      <Container style={{ width: '95%' }}>
        <Menu.Item header as="li">
          <Link to={APP_PATH.BASE}>Dealshield Admin</Link>
          <a className={label.color()}>{label.env}</a>
        </Menu.Item>

        {user.username && (
          <Menu.Item as="li">
            <Link to={APP_PATH.BASE}>Home</Link>
          </Menu.Item>
        )}

        {user.username && allowAccess(user, ACCESS.GUARANTEE_SECTION) && (
          <Dropdown item simple text="Guarantees">
            <Dropdown.Menu>
              {allowAccess(user, ACCESS.GUARANTEED_PURCHASES) && (
                <Dropdown.Item as="li">
                  <Link to={APP_PATH.GUARANTEED_PURCHASES}>
                    Guarantee Purchases
                  </Link>
                </Dropdown.Item>
              )}
              {allowAccess(user, ACCESS.RETURN_INVOICES) && (
                <Dropdown.Item as="li">
                  <Link to={APP_PATH.RETURN_INVOICES}>Return Invoices</Link>
                </Dropdown.Item>
              )}
              {allowAccess(user, ACCESS.EXTERNAL_TRANSACTIONS) && (
                <Dropdown.Item as="li">
                  <Link to={APP_PATH.EXTERNAL_AUCTION_TRANSACTIONS}>
                    Partner Auction Transactions
                  </Link>
                </Dropdown.Item>
              )}
              {allowAccess(user, ACCESS.NETWORK_PLUS_VEHICLE_ENTRY) && (
                <Dropdown.Item as="li">
                  <Link to={APP_PATH.NETWORK_PLUS_VEHICLE_ENTRY}>
                    Enter Network+ Vehicle
                  </Link>
                </Dropdown.Item>
              )}
              {allowAccess(user, ACCESS.EXTERNAL_VEHICLE_BATCHES) && (
                <Dropdown.Item as="li">
                  <Link to={APP_PATH.EXTERNAL_VEHICLE_BATCHES}>
                    Network+ Batches
                  </Link>
                </Dropdown.Item>
              )}
              {allowAccess(user, ACCESS.EXTERNAL_TRANSACTIONS) && (
                <Dropdown.Item as="li">
                  <Link to={APP_PATH.EXTERNAL_TRANSACTIONS}>
                    External Auction Transactions
                  </Link>
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        )}

        {user.username && allowAccess(user, ACCESS.LOSS_PREVENTION_SECTION) && (
          <Dropdown item simple text="Loss Prevention">
            <Dropdown.Menu>
              {allowAccess(user, ACCESS.LOSS_PREVENTION_SECTION) && (
                <Dropdown.Item as="li">
                  <Link to={APP_PATH.PENDING_GUARANTEES}>
                    Pending Guarantees Offers
                  </Link>
                </Dropdown.Item>
              )}
              {allowAccess(user, ACCESS.LOSS_PREVENTION_SECTION) && (
                <Dropdown.Item as="li">
                  <Link to={APP_PATH.VIN_BLACKLISTS}>Vin Blacklists</Link>
                </Dropdown.Item>
              )}
              {allowAccess(user, ACCESS.LOSS_PREVENTION_SECTION) && (
                <Dropdown.Item as="li">
                  <Link to={APP_PATH.AUCTIONACCESS_BLACKLISTS}>
                    Auction Access Blacklists
                  </Link>
                </Dropdown.Item>
              )}
              {allowAccess(user, ACCESS.LOSS_PREVENTION_SECTION) && (
                <Dropdown.Item as="li">
                  <Link to={APP_PATH.DEALERSHIP_BLACKLISTS}>
                    Dealership Blacklists
                  </Link>
                </Dropdown.Item>
              )}
              {allowAccess(user, ACCESS.LOSS_PREVENTION_SECTION) && (
                <Dropdown.Item as="li">
                  <Link to={APP_PATH.EXPIRED_VRA_SETTINGS}>
                    Expired VRA Settings
                  </Link>
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        )}

        {user.username && allowAccess(user, ACCESS.DEALSHIELD360_SECTION) && (
          <Dropdown item simple text="Dealshield 360">
            <Dropdown.Menu>
              {allowAccess(user, ACCESS.DEALSHIELD360_OFFERS) && (
                <Dropdown.Item as="li">
                  <Link to={APP_PATH.DEALSHIELD360_OFFERS}>
                    Dealshield 360 Offers
                  </Link>
                </Dropdown.Item>
              )}
              {allowAccess(user, ACCESS.UPGRADE_OFFERS) && (
                <Dropdown.Item as="li">
                  <Link to={APP_PATH.OFFER_UPGRADES}>Offer Upgrades</Link>
                </Dropdown.Item>
              )}
              {allowAccess(user, ACCESS.UPGRADE_OFFERS) && (
                <Dropdown.Item as="li">
                  <Link to={APP_PATH.BULK_DEALER_ACTIONS}>
                    Bulk Dealer Actions
                  </Link>
                </Dropdown.Item>
              )}
              {allowAccess(user, ACCESS.DEALER_DASHBOARD) &&
                showDashboard === 'true' && (
                  <Dropdown.Item as="li">
                    <Link to={APP_PATH.DEALER_DASHBOARD}>Dealer Dashboard</Link>
                  </Dropdown.Item>
                )}
              {allowAccess(user, ACCESS.SURCHARGE_PRICE) &&
                showDashboard === 'true' && (
                  <Dropdown.Item as="li">
                    <Link to={APP_PATH.SURCHARGE_PRICE_UPLOAD}>
                      Surcharge Price
                    </Link>
                  </Dropdown.Item>
                )}
            </Dropdown.Menu>
          </Dropdown>
        )}

        {user.username && allowAccess(user, ACCESS.FINANCE_SECTION) && (
          <Dropdown item simple text="Finance">
            <Dropdown.Menu>
              {allowAccess(user, ACCESS.GUARANTEE_HISTORY) && (
                <Dropdown.Item as="li">
                  <Link to={APP_PATH.GUARANTEE_HISTORY}>
                    Guarantee History Report
                  </Link>
                </Dropdown.Item>
              )}
              {allowAccess(user, ACCESS.GUARANTEE_CHARGE_SECTION) && (
                <Dropdown.Item as="li">
                  <Link to={APP_PATH.GUARANTEE_CHARGE}>
                    Guarantee Charge / Credit
                  </Link>
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        )}

        {user.username && allowAccess(user, ACCESS.ADMIN_SECTION) && (
          <Dropdown item simple text="Admin">
            <Dropdown.Menu>
              {allowAccess(user, ACCESS.DEALER_INFORMATION) && (
                <Dropdown.Item as="li">
                  <Link to={APP_PATH.DEALER_INFORMATION}>
                    Dealer Information
                  </Link>
                </Dropdown.Item>
              )}

              {allowAccess(user, ACCESS.MARKET_INDEX) && (
                <Dropdown.Item as="li">
                  <Link to={APP_PATH.MARKET_INDEX}>Market Index</Link>
                </Dropdown.Item>
              )}

              {allowAccess(user, ACCESS.MARKET_INDEX) && (
                <Dropdown.Item as="li">
                  <Link to={APP_PATH.MARKET_FORECAST_UPLOAD}>
                    Market Forecast Uploader
                  </Link>
                </Dropdown.Item>
              )}

              {allowAccess(user, ACCESS.USER_DIGEST_SETTINGS) && (
                <Dropdown.Item as="li">
                  <Link to={APP_PATH.USER_DIGEST_SETTINGS}>
                    User Digest Settings
                  </Link>
                </Dropdown.Item>
              )}

              {allowAccess(user, ACCESS.FEATURE_FLAG_SECTION) && (
                <Dropdown.Item as="li">
                  <Link to={APP_PATH.FEATURE_FLAG}>Feature Flag</Link>
                </Dropdown.Item>
              )}

              {allowAccess(user, ACCESS.USERS_SECTION) && (
                <Dropdown.Item as="li">
                  <Link to={APP_PATH.USERS}>Users</Link>
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        )}

        {user.username && allowAccess(user, ACCESS.IT_ADMINISTRATION_SECTION) && (
          <Dropdown item simple text="IT Support">
            <Dropdown.Menu>
              <Dropdown.Item as="li">
                <Link to={APP_PATH.SUBSCRIPTION}>Subscription Manager</Link>
              </Dropdown.Item>

              <Dropdown.Item as="li">
                <Link to={APP_PATH.SUPPORT_REQUEST}>Support Requests</Link>
              </Dropdown.Item>

              <Dropdown.Item as="li">
                <Link to={APP_PATH.FILE_UPLOADER}>File Uploader</Link>
              </Dropdown.Item>

              <Dropdown.Item as="li">
                <Link to={APP_PATH.DEALER_MANAGER}>Dealer Manager</Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}

        {user.username && (
          <Menu.Menu position="right">
            <Menu.Item header>
              Logged in as &nbsp;<i>{user.username}</i>
            </Menu.Item>
            <Menu.Item>
              <Link to={APP_PATH.LOGOUT}>Logout</Link>
            </Menu.Item>
          </Menu.Menu>
        )}

        {!user.username && !pingEnabled && (
          <Menu.Menu position="right">
            <Menu.Item>
              <Link to={APP_PATH.LOGIN}>Login</Link>
            </Menu.Item>
          </Menu.Menu>
        )}
      </Container>
    </Menu>
  </div>
);

HeaderComponent.propTypes = {
  user: PropTypes.object
};

export default connect(() => {
  let env_name = process.env.REACT_APP_ENV || 'ENV name not set';
  let label = {
    env: env_name.toUpperCase(),
    color: function() {
      switch (env_name.toLowerCase()) {
        case 'production' || 'prod':
          return 'ui label red';
        case 'qa':
          return 'ui label yellow';
        case 'staging':
          return 'ui label green';
        default:
          return 'ui label black';
      }
    }
  };
  return {
    label: label
  };
})(HeaderComponent);
